import React from "react";
import { graphql } from "gatsby";
import Layout from "../Layout/Layout";
import RecruitmentTasksPL from "../modules/Recruitment/components/RecruitmentTasksPL";
import SEO from "../Layout/Seo";
import renderHTML from "../helpers/renderHTML";

const PageSingleRecruitmentPLTemplate = props => {
  const recruitment = props.data.wordpressWpRecruitmentPl;

  return (
    <Layout>
      <SEO
        title={recruitment.yoast.title ? recruitment.yoast.title : recruitment.title}
        keywords={recruitment.yoast.metakeywords}
        description={recruitment.yoast.metadesc}
        robots="NOINDEX,FOLLOW"
      />
      <div className="recruitment-wrapper" id={`recruitment-${recruitment.id}`}>
        <div className="section__header">
          <h2
            className="section__title--large"
            dangerouslySetInnerHTML={renderHTML(recruitment.title)}
          />
          <h5 className="section__title--subtitle">
            <p>
              {recruitment.id === "2babb70d-717e-55a5-a4c1-1816dd9cde16" ? (
                <>
                  Prosimy wykonać zadania i przesłać ich rozwiązania w formacie PDF na adres
                  e-mail&nbsp;
                </>
              ) : (
                <>
                  Proszę wykonać zadania i przesłać link do repozytorium z zadaniem (preferowany
                  Github) na adres&nbsp;
                </>
              )}
              <a href="mailto:joinpandas@pandagroup.co">joinpandas@pandagroup.co</a>.
            </p>
          </h5>
        </div>
        <section className="recruitment">
          <RecruitmentTasksPL recruitment={recruitment} />
        </section>
      </div>
    </Layout>
  );
};

export default PageSingleRecruitmentPLTemplate;

export const pageQuery = graphql`
  query currentRecruitmentPlPageQuery($id: String!) {
    wordpressWpRecruitmentPl(id: { eq: $id }) {
      id
      title
      yoast {
        title
        metakeywords
        metadesc
      }
      acf {
        recruitment_tasks {
          task_additional_text
          task_content
          task_files
          task_title
        }
      }
    }
  }
`;
