import React from "react";
import renderHTML from "../../../helpers/renderHTML";

const Service = ({ recruitment }) => {
  let taskNumber = 0;

  return (
    <>
      {recruitment.acf.recruitment_tasks.map(item => {
        taskNumber += 1;

        return (
          <>
            <div className="container">
              <section className="recruitment__section services">
                <div className="content-description">
                  <div className="hex">
                    <div className="hex inner">
                      <span className="num">0{taskNumber}.</span>
                    </div>
                  </div>
                  <span className="content-description__title">Zadanie {taskNumber}.</span>
                </div>
                <section className="task">
                  <div className="task__header">
                    <h3>{item.task_title}</h3>
                  </div>
                  <div className="task__content">
                    <div dangerouslySetInnerHTML={renderHTML(item.task_content)} />
                    {item.task_files.length > 0 ? (
                      <div
                        className="task__files"
                        dangerouslySetInnerHTML={renderHTML(item.task_files)}
                      />
                    ) : (
                      <></>
                    )}

                    {item.task_additional_text.length > 0 ? (
                      <div className="task__additional">
                        <h4>Uwagi do zadania:</h4>
                        <div dangerouslySetInnerHTML={renderHTML(item.task_additional_text)} />
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </section>
              </section>
            </div>
            <div className="horizontal-line" />
          </>
        );
      })}
      <h4 className="mail-information">
        <p>
          {recruitment.id === "2babb70d-717e-55a5-a4c1-1816dd9cde16" ? (
            <>
              Prosimy wykonać zadania i przesłać ich rozwiązania w formacie PDF na adres
              e-mail&nbsp;
            </>
          ) : (
            <>
              Proszę wykonać zadania i przesłać link do repozytorium z zadaniem (preferowany Github)
              na adres&nbsp;
            </>
          )}
          <a href="mailto:joinpandas@pandagroup.co">joinpandas@pandagroup.co</a>.
        </p>
      </h4>
    </>
  );
};

export default Service;
